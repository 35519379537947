<script>
import { formatDate } from '/~/utils/format/date'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useUserStats } from '/~/composables/user-stats'
import HomeChart from '/~/templates/bill-payments/components/chart/chart.vue'
import StatisticCard from './statistic-card.vue'

export default {
  name: 'payments-overview',
  components: {
    HomeChart,
    StatisticCard,
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Bill Payments Overview',
    },
    defaultFilter: {
      type: String,
      default: '30d',
    },
    showChart: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const {
      statsLoading,
      statsTotal,
      stats,
      statsFormat,
      statsFilter,
      fetchUserStats,
      updateUserStats,
      initFilter,
    } = useUserStats()

    initFilter(props.defaultFilter)

    fetchUserStats()

    return {
      statsLoading,
      statsTotal,
      stats,
      statsFormat,
      statsFilter,
      fetchUserStats,
      updateUserStats,
      useUserStats,
    }
  },
  data() {
    return {
      filterOptions: [
        {
          text: 'Last 7 days',
          value: '7d',
        },
        {
          text: 'Last 30 days',
          value: '30d',
        },
        {
          text: 'Last 60 days',
          value: '60d',
        },
        {
          text: 'Last 90 days',
          value: '90d',
        },
        {
          text: 'All',
          value: 'all',
        },
      ],
    }
  },
  computed: {
    hasStats() {
      return this.stats?.length > 1 || false
    },
    datasets() {
      const amount = []
      const pointsBalance = []
      const labels = []
      let ordersSubtotaValue = 0
      let pointsBalanceValue = 0

      this.stats.forEach((el) => {
        ordersSubtotaValue = el.ordersSubtotal ?? ordersSubtotaValue
        pointsBalanceValue = el.pointsBalance ?? pointsBalanceValue
        amount.push(ordersSubtotaValue)
        pointsBalance.push(pointsBalanceValue)
        labels.push(
          this.statsFormat === 'monthly'
            ? formatDate('monthshort', el.month)
            : formatDate('daymonth', el.day)
        )
      })

      return {
        data: [
          {
            label: 'Total Amount',
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 160)

              gradient.addColorStop(0, 'rgba(83, 64, 194, 0.7)')
              gradient.addColorStop(1, 'rgba(83, 64, 194, 0.1)')

              return gradient
            },
            fill: true,
            borderColor: '#7C3AED',
            strokeStyle: '#7C3AED',
            borderWidth: 2,
            data: amount.filter((item) => item !== null),
            radius: 0,
            hidden: false,
            yAxisID: 'yAxes',
            tension: 0.4,
            order: 1,
          },
          {
            label: 'Points Balance',
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 160)

              gradient.addColorStop(0, 'rgba(70, 201, 227, 0.9)')
              gradient.addColorStop(1, 'rgba(70, 201, 227, 0.18)')

              return gradient
            },
            fill: true,
            borderColor: '#06B6D4',
            strokeStyle: '#06B6D4',
            pointStyle: 'dash',
            borderWidth: 2,
            borderDash: [5, 2],
            data: pointsBalance.filter((item) => item !== null),
            radius: 0,
            hidden: false,
            yAxisID: 'yAxes1',
            tension: 0.4,
            order: 0,
          },
        ],
        labels,
      }
    },
  },
}
</script>

<template>
  <div class="mx-auto space-y-5">
    <div class="flex items-center justify-between">
      <div
        v-if="title"
        class="text-xl font-bold text-eonx-neutral-800"
        v-html="title"
      />
      <div class="relative">
        <select
          v-model="statsFilter"
          class="appearance-none h-10 min-w-40 cursor-pointer rounded-md border border-solid bg-white pl-2.5 text-eonx-neutral-600"
          @change="updateUserStats($event.target.value)"
        >
          <option disabled value="">Filter By:</option>
          <option
            v-for="opt in filterOptions"
            :key="opt.value"
            :value="opt.value"
          >
            {{ opt.text }}
          </option>
        </select>

        <div
          class="pointer-events-none absolute right-0 top-1/2 mr-[15px] -translate-y-1/2 transform leading-3 text-eonx-neutral-800"
        >
          <base-icon svg="plain/chevron-down" size="sm" />
        </div>
      </div>
    </div>

    <div class="mx-auto grid grid-cols-3 gap-2.5">
      <statistic-card
        icon="symbion/home/dollar"
        title="Total bill payments"
        :value="parseFloat(statsTotal.ordersSubtotal || 0, 10).toFixed(2)"
        :loading="statsLoading"
        is-currency
      />

      <statistic-card
        icon="symbion/home/coins"
        title="Transaction volume"
        :value="parseFloat(statsTotal.ordersCount || 0, 10).toFixed(2)"
        :loading="statsLoading"
      />

      <statistic-card
        title="Points earned"
        sub-value="PTS"
        :value="statsTotal.pointsEarned || 0"
        :loading="statsLoading"
        is-points
      >
        <template #icon>
          <div
            class="flex h-6 w-6 items-center justify-center rounded-full bg-primary-lightest"
          >
            <base-icon
              svg="v2/custom/points-token"
              :size="14"
              class="text-primary"
            />
          </div>
        </template>
      </statistic-card>
    </div>

    <home-chart
      v-if="showChart"
      class="w-full max-w-full rounded-lg"
      :labels="datasets.labels"
      :datasets="datasets.data"
      :loading="statsLoading"
      :has-data="hasStats"
    />
  </div>
</template>
